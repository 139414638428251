import { createSelector } from '@reduxjs/toolkit';

import { name } from './reducers';
import { area } from '../common/selectors';


export const selectArea = area(name);

export const selectOpenedFilter = createSelector(selectArea, ({ openedFilter }) => openedFilter);
export const selectFiltersData = createSelector(selectArea, ({ filtersData }) => filtersData);
export const selectFiltersCounts = createSelector(selectFiltersData, (filters) => {
  const showMe = Number(!!(filters.hasOffPlan
    || filters.hasResale
    || filters.resale
    || !!filters.statuses.length));

  const favorite = Number(!!filters.favorite);

  const price = Number(!!(filters.minPrice || filters.maxPrice || filters.hasPostHandover));
  const size = Number(!!(filters.minSquare || filters.maxSquare));
  const handover = Number(!!(filters.handoverFrom || filters.handoverTo || !!filters.isBuilt || filters.offPlan));
  const layout = Number(!!filters.layouts.length);
  const unitType = Number(!!filters.unitTypeIds.length);
  const unitLevel = Number(!!filters.unitLevels.length);
  const furnishing = Number(!!filters.finishingIds.length);
  const serviceCharge = Number(!!(filters.minServiceCharge || filters.maxServiceCharge));

  return {
    emirate: filters.emirateIds.length,
    district: filters.districtIds.length,
    developer: filters.developerIds.length,
    other: showMe + price + size + handover + layout + unitType + unitLevel + furnishing + serviceCharge + favorite,
  };
});
