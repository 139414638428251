import { t } from 'i18next';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { setToken } from 'store/common/utils';
import { abortController } from 'store/utils';

import {
  authApi,
  forgotPasswordApi,
  resetPasswordApi,
  userApi,
} from './api';


const authToken = window.localStorage.getItem('token');

export default create(devtools(
  immer((set) => abortController({
    token: authToken,
    user: {},
    errors: {
      auth: '',
      forgot: '',
      reset: '',
    },

    logout: (payload, { setState }) => {
      localStorage.setItem('token', '');
      localStorage.setItem('role', undefined);
      window.location.href = '/';

      setState({ token: false });
    },

    login: async ({ email, password }, { setState }) => {
      try {
        const { data: { token } } = await authApi({ email, password });

        window.localStorage.setItem('token', token);
        setToken(`Bearer ${token}`);

        setState((state) => {
          state.token = token;
          state.errors.auth = '';
        }, 'fulfilled');
      } catch (error) {
        setState(({ errors }) => {
          errors.auth = t('common.authError');
        }, 'error');

        throw new Error(error.response.data.errors);
      }
    },

    getUser: async (payload, { signal, setState }) => {
      try {
        const { data: user } = await userApi(signal);
        localStorage.setItem('role', user.role);

        setState({ user }, 'fulfilled');

        return user;
      } catch (error) {
        throw new Error(error);
      }
    },

    forgotPassword: async (email, { setState }) => {
      try {
        const { data } = await forgotPasswordApi({ email });

        setState(({ errors }) => { errors.forgot = ''; }, 'fulfilled');

        return data;
      } catch (error) {
        setState(({ errors }) => {
          errors.forgot = error.response.data.errors;
        }, 'error');

        throw new Error(error.response.data.errors);
      }
    },

    resetPassword: async (payload, { setState }) => {
      try {
        const { data } = await resetPasswordApi(payload);

        setState(({ errors }) => { errors.reset = ''; }, 'fulfilled');

        return data;
      } catch (error) {
        setState(({ errors }) => {
          errors.reset = error.response.data.errors;
        }, 'error');

        throw new Error(error.response.data.errors);
      }
    },

  }, set, 'Auth')),
  {
    name: 'Auth',
  },
));

setToken(`Bearer ${authToken}`);
