import { createAsyncThunk } from '@reduxjs/toolkit';

import measurementsStore from 'store/measurements';
import { selectFiltersData } from 'store/filters/selectors';
import { CurrencyToAED, mToUnit } from 'components/utils';

import {
  getLightProjectsApi,
  getProjectApi,
  createProjectApi,
  editProjectApi,
  uploadUnitsApi,
  getUnitsApi,
  getAmenitiesApi,
  syncAmenitiesApi,
  getProjectAmenitiesApi,
  getPaymentPlansApi,
  editPaymentPlanApi,
  deletePaymentPlanApi,
  getProjectPaymentPlansApi,
  createPaymentPlanApi,
  syncProjectPaymentPlansApi,
  syncProjectFinishesApi,
  getFinishesApi,
  getProjectFinishesApi,
  deleteProjectApi,
  getUnitTypesApi,
  getUnitLayoutsApi,
  createPaymentPlanItemApi,
  editPaymentPlanItemApi,
  deletePaymentPlanItemApi,
  getProjectDocumentsApi,
  getConfigApi,
  downloadUnitsApi,
  addToFavoriteApi,
  deleteFromFavoriteApi,
  unitsScanApi,
  syncUnitsApi,
} from './api';

import { uploadAndAttachFiles, detachFiles, updateFiles } from '../common/thunks';


export const getProjects = createAsyncThunk(
  'projects/get',
  async ({ withFilters, ...params } = {}, { signal, getState }) => {
    const state = getState();
    const filters = selectFiltersData(state);
    const { exchange, priceMeasurement, squareMeasurement } = measurementsStore.getState();
    const serviceChargeRatio = mToUnit(1, squareMeasurement, true);
    const {
      minPrice, maxPrice, minSquare, maxSquare, minServiceCharge, maxServiceCharge,
    } = filters;

    const payload = {
      ...filters,
      minPrice: CurrencyToAED(minPrice, priceMeasurement, exchange, true),
      maxPrice: CurrencyToAED(maxPrice, priceMeasurement, exchange, true),
      minSquare: mToUnit(minSquare, squareMeasurement, true),
      maxSquare: mToUnit(maxSquare, squareMeasurement, true),
      minServiceCharge: minServiceCharge ? CurrencyToAED(minServiceCharge, priceMeasurement, exchange, true) / serviceChargeRatio : undefined,
      maxServiceCharge: maxServiceCharge ? CurrencyToAED(maxServiceCharge, priceMeasurement, exchange, true) / serviceChargeRatio : undefined,
    };

    const { data } = await getLightProjectsApi({ ...(withFilters ? payload : {}), ...params }, signal);

    return data;
  },
);

export const getProject = createAsyncThunk(
  'project/get',
  async (projectId, { signal }) => {
    const { data } = await getProjectApi(projectId, signal);

    return data;
  },
);

export const createProject = createAsyncThunk(
  'project/create',
  async (project, { rejectWithValue }) => {
    try {
      const { data } = await createProjectApi(project);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editProject = createAsyncThunk(
  'project/edit',
  async (project, { rejectWithValue }) => {
    try {
      const { data } = await editProjectApi(project);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteProject = createAsyncThunk(
  'project/delete',
  async (project) => {
    await deleteProjectApi(project);

    return project;
  },
);

export const getProjectPaymentPlans = createAsyncThunk(
  'project/getPaymentPlans',
  async (id) => {
    const { data } = await getProjectPaymentPlansApi(id);

    return data;
  },
);

export const getProjectAmenities = createAsyncThunk(
  'project/getAmenities',
  async (id) => {
    const { data } = await getProjectAmenitiesApi(id);

    return data;
  },
);

export const getProjectUnits = createAsyncThunk(
  'project/getUnits',
  async (projectId, { getState, signal }) => {
    const state = getState();
    const filters = selectFiltersData(state);
    const { exchange, priceMeasurement, squareMeasurement } = measurementsStore.getState();
    const serviceChargeRatio = mToUnit(1, squareMeasurement, true);
    const {
      minPrice, maxPrice, minSquare, maxSquare, minServiceCharge, maxServiceCharge,
    } = filters;

    const payload = {
      ...filters,
      minPrice: CurrencyToAED(minPrice, priceMeasurement, exchange, true),
      maxPrice: CurrencyToAED(maxPrice, priceMeasurement, exchange, true),
      minSquare: mToUnit(minSquare, squareMeasurement, true),
      maxSquare: mToUnit(maxSquare, squareMeasurement, true),
      minServiceCharge: minServiceCharge ? CurrencyToAED(minServiceCharge, priceMeasurement, exchange, true) / serviceChargeRatio : undefined,
      maxServiceCharge: maxServiceCharge ? CurrencyToAED(maxServiceCharge, priceMeasurement, exchange, true) / serviceChargeRatio : undefined,
    };
    const { data } = await getUnitsApi(projectId, payload, signal);

    return data;
  },
);

export const downloadUnits = createAsyncThunk(
  'project/downloadUnits',
  async (projectId) => {
    const { data } = await downloadUnitsApi(projectId);

    return data;
  },
);

export const uploadProjectUnits = createAsyncThunk(
  'project/uploadUnits',
  async ({ projectId, file }, { rejectWithValue }) => {
    const form = new FormData();
    form.append('file', file);
    try {
      const { data } = await uploadUnitsApi(projectId, form);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const syncProjectUnits = createAsyncThunk(
  'project/syncUnits',
  async ({ projectId, payload }, { rejectWithValue }) => {
    try {
      const { data } = await syncUnitsApi(projectId, { rows: payload });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const unitsScan = createAsyncThunk(
  'project/unitsScan',
  async ({ projectId, file }, { rejectWithValue }) => {
    const form = new FormData();
    form.append('file', file);
    try {
      const { data } = await unitsScanApi({ projectId, data: form });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectDocuments = createAsyncThunk(
  'project/getDocuments',
  async (projectId) => {
    const { data } = await getProjectDocumentsApi({ projectId, params: { scope: 'documents' } });

    return data;
  },
);

export const getUnitTypes = createAsyncThunk(
  'unitTypes/get',
  async () => {
    const { data } = await getUnitLayoutsApi();

    return data;
  },
);

export const getUnitLayouts = createAsyncThunk(
  'unitLayouts/get',
  async () => {
    const { data } = await getUnitTypesApi();

    return data;
  },
);

// amenities
export const getAmenities = createAsyncThunk(
  'amenities/get',
  async () => {
    const { data } = await getAmenitiesApi();

    return data;
  },
);

export const syncAmenities = createAsyncThunk(
  'project/syncAmenities',
  async ({ projectId, data: payload }) => {
    const { data } = await syncAmenitiesApi(projectId, { ids: payload });

    return data;
  },
);

// payment plans
export const getPaymentPlans = createAsyncThunk(
  'paymentPlans/get',
  async () => {
    const { data } = await getPaymentPlansApi();

    return data;
  },
);

export const syncProjectPaymentPlans = createAsyncThunk(
  'paymentPlans/sync',
  async ({ id, payload }) => {
    const { data } = await syncProjectPaymentPlansApi(id, { ids: payload });

    return data;
  },
);

export const getPaymentPlan = createAsyncThunk(
  'paymentPlan/get',
  async (id) => {
    const { data } = await getPaymentPlansApi(id);

    return data;
  },
);

export const createPaymentPlan = createAsyncThunk(
  'paymentPlan/create',
  async (payload) => {
    const { data } = await createPaymentPlanApi(payload);

    return data;
  },
);

export const editPaymentPlan = createAsyncThunk(
  'paymentPlan/edit',
  async (paymentPlan) => {
    const { data } = await editPaymentPlanApi(paymentPlan);

    return data;
  },
);

export const deletePaymentPlan = createAsyncThunk(
  'paymentPlan/delete',
  async (paymentPlan) => {
    await deletePaymentPlanApi(paymentPlan);

    return paymentPlan;
  },
);

export const createPaymentPlanItem = createAsyncThunk(
  'paymentPlanItem/create',
  async (paymentPlan) => {
    const { data } = await createPaymentPlanItemApi(paymentPlan);

    return data;
  },
);

export const editPaymentPlanItem = createAsyncThunk(
  'paymentPlanItem/edit',
  async (paymentPlan) => {
    const { data } = await editPaymentPlanItemApi(paymentPlan);

    return data;
  },
);

export const deletePaymentPlanItem = createAsyncThunk(
  'paymentPlanItem/delete',
  async (paymentPlan) => {
    await deletePaymentPlanItemApi(paymentPlan);

    return paymentPlan;
  },
);

// finishes
export const syncProjectFinishes = createAsyncThunk(
  'project/syncFinishes',
  async ({ id, data: payload }) => {
    const { data } = await syncProjectFinishesApi(id, payload);

    return data;
  },
);

export const getProjectFinishes = createAsyncThunk(
  'project/getFinishes',
  async (id) => {
    const { data } = await getProjectFinishesApi(id);

    return data;
  },
);

export const getFinishes = createAsyncThunk(
  'finishes/get',
  async () => {
    const { data } = await getFinishesApi();

    return data;
  },
);

// renderers

export const attachRenderers = createAsyncThunk(
  'project/attachRenderers',
  async ({
    items,
    modelId,
  }, { dispatch }) => {
    const { payload } = await dispatch(uploadAndAttachFiles({
      items,
      modelId,
      scope: 'renderers',
      modelType: 'project',
    }));

    return { id: modelId, renderers: payload };
  },
);

export const updateRenderers = createAsyncThunk(
  'project/updateRenderers',
  async ({ items }, { dispatch }) => {
    const { payload } = await dispatch(updateFiles({ items }));

    return payload;
  },
);

export const detachRenderers = createAsyncThunk(
  'project/detachRenderers',
  async ({ ids }, { dispatch }) => {
    const { data } = await dispatch(detachFiles({ ids }));

    return data;
  },
);

// floorPlans

export const attachFloorPlans = createAsyncThunk(
  'project/attachFloorPlans',
  async ({
    items,
    modelId,
  }, { dispatch }) => {
    const { payload } = await dispatch(uploadAndAttachFiles({
      items,
      modelId,
      scope: 'layout',
      modelType: 'unit',
    }));

    return { id: modelId, layoutFiles: payload };
  },
);

export const updateFloorPlans = createAsyncThunk(
  'project/updateFloorPlans',
  async ({ items }, { dispatch }) => {
    const { payload } = await dispatch(updateFiles({ items }));

    return payload;
  },
);

export const detachFloorPlans = createAsyncThunk(
  'project/detachFloorPlans',
  async ({ ids }, { dispatch }) => {
    const { data } = await dispatch(detachFiles({ ids }));

    return data;
  },
);

// favorite

export const addToFavorite = createAsyncThunk(
  'project/addToFavorite',
  async (id) => {
    await addToFavoriteApi(id);

    return id;
  },
);

export const deleteFromFavorite = createAsyncThunk(
  'project/deleteFromFavorite',
  async (id) => {
    await deleteFromFavoriteApi(id);

    return id;
  },
);

// common

export const getConfig = createAsyncThunk(
  'config/get',
  async () => {
    const { data } = await getConfigApi();

    return data;
  },
);
