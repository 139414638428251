/* eslint-disable no-new */
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { load } from '@2gis/mapgl';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from 'assets/lottie/Loader';
import WS from 'store/common/ws';

import routes from 'router';
import store from 'store';

import './i18n';
import './index.scss';


new WS(store);

console.log(process.env.npm_package_version); // eslint-disable-line

load().then(() => {
  createRoot(document.getElementById('root')).render(
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {routes.map(
            ({ path, element: Element }) => (
              <Route
                key={path}
                path={path}
                element={(
                  <Suspense fallback={<Loader />}>
                    <Element />
                  </Suspense>
                )}
              />
            ),
          )}
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="bottom-left"
        autoClose={10000}
        hideProgressBar={false}
        limit={3}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Provider>,
  );
});
