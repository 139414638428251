import { createNextState } from '@reduxjs/toolkit';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { transform } from 'ol/proj';


/* File.prototype.toBase64 = function () { // eslint-disable-line
  return new Promise((res) => {
    const reader = new FileReader();
    reader.onloadend = (e) => res(e.target.result);
    reader.readAsDataURL(this);
  });
};

Blob.prototype.toBase64 = function () { // eslint-disable-line
  return new Promise((res) => {
    const reader = new FileReader();
    reader.onloadend = (e) => res(e.target.result);
    reader.readAsDataURL(this);
  });
}; */

Date.prototype.format = function (string) { // eslint-disable-line
  const dateTimeFormat = new Intl.DateTimeFormat(this, {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  });
  const parts = dateTimeFormat.formatToParts(this).reduce((acc, { type, value }) => ({ ...acc, [type]: value }), {});
  const addZero = (value) => (Number(value) < 10 ? `0${Number(value)}` : value);

  return string
    .replaceAll('YYYY', parts.year)
    .replaceAll('YY', [...('' + parts.year)].slice(-2).join('')) // eslint-disable-line
    .replaceAll('MM', addZero(parts.month))
    .replaceAll('DD', addZero(parts.day))
    .replaceAll('hh', addZero(parts.hour))
    .replaceAll('mm', addZero(parts.minute))
    .replaceAll('ss', parts.second);
};

const useToggle = (defaultState) => {
  const [state, setState] = useState(defaultState);

  const toggle = useCallback(() => setState((a) => !a), []);
  const force = useCallback((a) => setState(a), []);

  return [state, toggle, force];
};

const setExecTime = (from, to) => {
  to.setHours(from.getHours());
  to.setMinutes(from.getMinutes());
  to.setSeconds(from.getSeconds());
  to.setMilliseconds(from.getMilliseconds());
  return to;
};

const getDaysToDate = (from) => {
  if (!from) return undefined;
  const date = new Date();
  const expiredAt = new Date(from);
  const difference = expiredAt.getTime() - setExecTime(expiredAt, date).getTime();
  return difference / (1000 * 60 * 60 * 24);
};

const useDebounce = (value, delay) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(setState, delay, value);
    return () => clearTimeout(timeoutId);
  }, [value, delay]);

  return state;
};

const debounce = (delay) => {
  let timerId;

  return (callback) => {
    clearTimeout(timerId);
    timerId = setTimeout(callback, delay);
  };
};

const useDidMountEffect = (callback, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) didMount.current = true;
    else return callback();
  }, deps); // eslint-disable-line
};

const squareRatios = { 'm²': 0.092903, 'ft²': 1 };
const ftToUnit = (square, unit, asNumber) => {
  if (!square || !unit) return undefined;
  if (asNumber) return Number((square * squareRatios[unit]));
  return `${Number((square * squareRatios[unit]).toFixed(2)).toLocaleString()} ${unit}`;
};

const mToUnit = (square, unit, asNumber) => {
  if (!square || !unit) return undefined;
  if (asNumber) return Number((square / squareRatios[unit]));
  return `${Number((square / squareRatios[unit]).toFixed(2)).toLocaleString()} ${unit}`;
};

const AEDtoCurrency = (price, unit, exchange, asNumber, withCurrency = true) => {
  if (!price || !unit || !exchange) return undefined;
  if (asNumber) return Number((price * exchange?.[unit]).toFixed(2));
  if (!withCurrency) return Number((price * exchange?.[unit]).toFixed(2)).toLocaleString();
  return `${Number((price * exchange?.[unit]).toFixed(2)).toLocaleString()} ${unit}`;
};

const CurrencyToAED = (price, unit, exchange, asNumber) => {
  if (!price || !unit || !exchange) return undefined;
  if (asNumber) return Number((price / exchange?.[unit]).toFixed(2));
  return `${Number((price / exchange?.[unit]).toFixed(2)).toLocaleString()} AED`;
};

const formatHandover = (date) => {
  if (!date) return;
  const year = Number(date.split('-')[0]);
  const month = Number(date.split('-')[1]);
  return `Q${Math.ceil((month / 12) * 4)}/${year}`;
};

const toggleArrayItem = (item, array) => {
  if (array.includes(item)) return array.filter((arrayItem) => arrayItem !== item);
  return [...array, item];
};

const get = (obj, path) => {
  if (!path) return obj;
  const child = path?.split('.');
  return get(obj?.[child?.[0]], child?.slice(1)?.join('.'));
};

const set = (val, path, obj) => {
  if (!path) return obj;
  const child = path?.split('.');
  const key = child[0];

  return child.length === 1
    ? createNextState(obj, (draft) => {
      draft[key] = val;
    })
    : createNextState(obj, (draft) => {
      draft[key] = set(val, child.slice(1).join('.'), draft[key]);
    });
};

const sortBy = (arr, path, direction = true) => (
  [...arr].sort((a, b) => (
    // eslint-disable-next-line no-nested-ternary
    get(a, path) > get(b, path)
      ? (direction ? 1 : -1)
      // eslint-disable-next-line no-nested-ternary
      : get(a, path) < get(b, path)
        ? (direction ? -1 : 1)
        : 0
  ))
);

const copyToBuffer = (value) => {
  window.navigator.clipboard.writeText(value);
};

const useMemoizedState = (value) => {
  const [state, setState] = useState(value);

  useEffect(() => { setState(value); }, [value]);

  return [state, setState];
};

const counter = (val) => {
  let i = 0;

  return () => {
    i = (val === i) ? 0 : i + 1;
    return i;
  };
};

const isEmpty = (data) => (Array.isArray(data) ? !data.length : !Object.keys(data).length);

const toProxy = (url) => url?.replace('https://', 'https://reverse.geniemap.net/');

const toLonLat = (geometry) => {
  if (!Number.isNaN(1 * geometry[0])) {
    const [x, y] = transform(geometry, 'EPSG:3857', 'EPSG:4326');
    return [+x.toFixed(6), +y.toFixed(6)];
  }

  return geometry.map(toLonLat);
};

const parseQuery = (url) => url.split(/[?|&]([^=&]+)=([^&]*)/gm)
  .filter(Boolean)
  .reduce((acc, item, i, arr) => (
    arr[i * 2] ? {
      ...acc,
      [arr[i * 2]]: arr[i * 2 + 1],
    } : acc
  ), {});

const fromLonLat = (geometry) => {
  if (!Number.isNaN(1 * geometry[0])) {
    const [x, y] = transform(geometry, 'EPSG:4326', 'EPSG:3857');
    return [+x.toFixed(6), +y.toFixed(6)];
  }

  return geometry.map(fromLonLat);
};

const moveItemToArray = (arrFrom, arrTo, indexFrom, indexTo) => {
  const sourceClone = Array.from(arrFrom);
  const destClone = Array.from(arrTo);
  const [removed] = destClone.splice(indexFrom, 1);

  sourceClone.splice(indexTo, 0, removed);
  return [sourceClone, destClone];
};

const downloadFile = (file, name) => {
  const objUrl = window.URL.createObjectURL(file);

  const link = document.createElement('a');
  link.href = objUrl;
  link.download = name;
  link.click();

  setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
};

export {
  useToggle,
  ftToUnit,
  mToUnit,
  AEDtoCurrency,
  CurrencyToAED,
  useDebounce,
  debounce,
  useDidMountEffect,
  get,
  set,
  sortBy,
  formatHandover,
  toggleArrayItem,
  useMemoizedState,
  copyToBuffer,
  counter,
  isEmpty,
  toProxy,
  toLonLat,
  parseQuery,
  setExecTime,
  getDaysToDate,
  fromLonLat,
  downloadFile,
  moveItemToArray,
};
